import React from "react";
import { makeStyles } from "@material-ui/core";
import { navigate } from "@reach/router";
import { Route } from "@interfaces";
import { Rokin } from "@images";

interface HeaderProps {
  simpleHeader?: boolean;
}

export const Header: React.FC<HeaderProps> = (simpleHeader) => {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <div className={classes.headerLeft}>
        <div className={classes.logo}>
          <img onClick={() => navigate(Route.index)} src={Rokin} alt="logo" />
          <div className={classes.logoText}>
            <span className={classes.logotxt}>rokin</span>
          </div>
        </div>
      </div>
    </header>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 90,
    alignItems: "center",
    background: "#F4F3F3",
    position: "fixed",
    width: "100%",
    zIndex: 999,
    "& span": {
      textTransform: "inherit",
    },
  },
  logotxt: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#0a5669",
    fontSize: "3rem",
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    alignItems: "end",
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 300,
    "& img": {
      width: 59,
      padding: "10px 0",
    },
    "& a": {
      fontSize: 14,
      [theme.breakpoints.down(1100)]: {
        display: "none",
      },
    },
    /* [theme.breakpoints.down(1100)]: {
      width: 170,
    }, */
  },
  logoText: {
    display: "grid",
    height: "100%",
    paddingLeft: 30,
  },
}));
