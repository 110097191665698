import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Header } from '../RegistrationStep1/Header';
import { Footer } from '../Landing/Footer';

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  simpleHeader?: boolean;
  styleFooter?: string;
}
export const LayoutRegistration: React.FC<LayoutProps> = ({
  className,
  children,
  simpleHeader,
  styleFooter,
}) => {
  const classes = useStyles();
  return (
    <div className={`${className || ''} ${classes.container}`}>
      <Header simpleHeader={simpleHeader} />
      <div className={classes.children}>{children}</div>
      <Footer className={`${classes.footer} ${styleFooter}`} />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: 'none',
    },
  },
  container: {
    overflowX: 'hidden',
    background: '#F4F3F3',
  },
  children: {
    marginTop: 90,
  },
}));
